<template>
  <mds-page-shell
    :logo="require('@/assets/logos/morningstar.svg')"
    :logo-responsive="require('@/assets/logos/morningstar.svg')"
    logo-href="/"
    logo-alt="Back to home page"
  >
    <template #mds-page-shell-masthead-right>
      <div class="rigth-content">
        <env-tag />
        <mds-button
          variation="flat"
          icon="person"
          flat-button-icon-size="medium"
          @click="logout"
        >
          Sign Out
        </mds-button>
      </div>
    </template>
    <div><router-view class="dap__container" /></div>
  </mds-page-shell>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import { MdsButton } from '@mds/button';
import { MODULE } from '@/constants/store.constant';
import AUTH from '@/constants/auth.constant';
import EnvTag from '@/components/ui/EnvironmentTag.vue';

export default {
  name: 'Empty',
  layout: 'Empty',
  components: {
    MdsPageShell,
    MdsButton,
    EnvTag,
  },
  methods: {
    logout () {
      this.$store.dispatch(`${MODULE.AUTH}/${AUTH.LOG_OUT}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.rigth-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between
}
</style>
